import getBestMedia from '@anm/helpers/getBestMedia';
import goodLinks from '@anm/data/goodEmbedLinks/links.json';
import config from '../../../config';
var NOT_FOUND_TITLE = 'Not found';
var NOT_FOUND_DESCRIPTION = 'Video landing is not found';
var PRIVATE_VIDEO_TITLE = 'This landing page is private';
var PRIVATE_VIDEO_DESCRIPTION = 'This landing page is private';

var getMetaByError = function getMetaByError(errorCode) {
  return {
    403: {
      title: PRIVATE_VIDEO_TITLE,
      description: PRIVATE_VIDEO_DESCRIPTION
    },
    404: {
      title: NOT_FOUND_TITLE,
      description: NOT_FOUND_DESCRIPTION
    }
  }[errorCode];
};

var getOEmbedUrl = function getOEmbedUrl(_ref) {
  var videoId = _ref.videoId,
      type = _ref.type,
      errorCode = _ref.errorCode;
  if (errorCode) return null;
  return "".concat(config.urls.embedUrl, "oembed.").concat(type, "?url=").concat(config.urls.embedUrl).concat(videoId);
};

export var getLandingMeta = function getLandingMeta(_ref2) {
  var _page$parameters, _page$parameters$meta, _manifest$meta, _page$parameters2, _page$parameters2$met;

  var page = _ref2.page,
      manifest = _ref2.manifest,
      error = _ref2.error;

  if (error !== null && error !== void 0 && error.code) {
    return getMetaByError(error === null || error === void 0 ? void 0 : error.code);
  }

  var pageTitle = page === null || page === void 0 ? void 0 : (_page$parameters = page.parameters) === null || _page$parameters === void 0 ? void 0 : (_page$parameters$meta = _page$parameters.meta) === null || _page$parameters$meta === void 0 ? void 0 : _page$parameters$meta.heading;
  var manifestTitle = manifest === null || manifest === void 0 ? void 0 : (_manifest$meta = manifest.meta) === null || _manifest$meta === void 0 ? void 0 : _manifest$meta.name;
  var pageDescription = page === null || page === void 0 ? void 0 : (_page$parameters2 = page.parameters) === null || _page$parameters2 === void 0 ? void 0 : (_page$parameters2$met = _page$parameters2.meta) === null || _page$parameters2$met === void 0 ? void 0 : _page$parameters2$met.bodyText;
  var title = pageTitle || manifestTitle || '';
  var description = pageDescription || pageTitle || manifestTitle || '';
  return {
    title: title,
    description: description
  };
};

var getPreviewUrl = function getPreviewUrl(_ref3) {
  var manifest = _ref3.manifest,
      error = _ref3.error;
  var canShowPreview = (manifest === null || manifest === void 0 ? void 0 : manifest.main) && !error;
  var previewUrl = canShowPreview ? getBestMedia(manifest.main, 'image').path : null;
  return previewUrl;
};

var getLandingUrl = function getLandingUrl(_ref4) {
  var page = _ref4.page,
      error = _ref4.error;
  if (!page || error !== null && error !== void 0 && error.code) return null;
  return "".concat(config.urls.landingUrl).concat(page.hostId || page.uploadId);
};

var getVideoMeta = function getVideoMeta(_ref5) {
  var error = _ref5.error,
      manifest = _ref5.manifest;
  if (!(manifest !== null && manifest !== void 0 && manifest.main) || error) return null;
  var bestVideo = getBestMedia(manifest.main, 'video');
  var videoUrl = bestVideo.path,
      videoWidth = bestVideo.width,
      videoHeight = bestVideo.height;
  return {
    videoUrl: videoUrl,
    videoWidth: videoWidth,
    videoHeight: videoHeight
  };
};

var getCanonicalUrl = function getCanonicalUrl(_ref6) {
  var videoId = _ref6.videoId,
      landingUrl = _ref6.landingUrl,
      errorCode = _ref6.errorCode;
  if (errorCode === 404) return "".concat(config.urls.waveUrl); // return https://wave.video here?

  var goodLink = goodLinks.watch.find(function (link) {
    return link.includes(videoId);
  });
  if (goodLink) return "".concat(config.urls.waveUrl, "embed/").concat(videoId); // not sure url is correct, here i use same link as for embeds

  return landingUrl;
};

export var parseMetaHeadProps = function parseMetaHeadProps(props) {
  var error = props.error,
      videoId = props.videoId;

  var _getLandingMeta = getLandingMeta(props),
      title = _getLandingMeta.title,
      description = _getLandingMeta.description;

  var previewUrl = getPreviewUrl(props);
  var landingUrl = getLandingUrl(props);
  var videoMeta = getVideoMeta(props);
  var oEmbedJSONUrl = getOEmbedUrl({
    videoId: videoId,
    type: 'json',
    errorCode: error === null || error === void 0 ? void 0 : error.code
  });
  var oEmbedXMLUrl = getOEmbedUrl({
    videoId: videoId,
    type: 'xml',
    errorCode: error === null || error === void 0 ? void 0 : error.code
  });
  var canonicalUrl = getCanonicalUrl({
    videoId: videoId,
    landingUrl: landingUrl,
    errorCode: error === null || error === void 0 ? void 0 : error.code
  });
  return {
    error: error,
    title: title,
    videoMeta: videoMeta,
    landingUrl: landingUrl,
    previewUrl: previewUrl,
    description: description,
    canonicalUrl: canonicalUrl,
    oEmbedXMLUrl: oEmbedXMLUrl,
    oEmbedJSONUrl: oEmbedJSONUrl
  };
};
export default parseMetaHeadProps;