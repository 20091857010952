import { FormatNames, Landing, LandingTheme } from '@anm/api/modules/hosting';
import AnalyticScript from '@anm/components/video-landings/AnalyticScript';
import Footer from '@anm/components/video-landings/Footer';
import { FC } from 'react';
import { css } from 'styled-components';

import { Wrapper } from './Wrapper';
import { injectFonts } from './helpers';
import useLayoutHeight from './hooks/useLayoutHeight';
export type { LandingTheme };

export type LayoutProps = {
  variant: LandingTheme;
  viewType?: FormatNames;
  playerColor: string;
  page?: Landing;
  hasFooter?: boolean;
  footerStyles?: ReturnType<typeof css>;
  isCustomization?: boolean;
};

const Layout: FC<LayoutProps> = ({
  hasFooter = true,
  page,
  children,
  viewType,
  footerStyles,
  isCustomization = false,
  ...wrapperProps
}) => {
  injectFonts({ isCustomization, currentTheme: wrapperProps.variant });

  const layoutRef = useLayoutHeight({ page, viewType, isCustomization });
  const { googleTrackId, facebookPixelTrackId } = page?.parameters?.track || {};

  return (
    <Wrapper ref={layoutRef} {...wrapperProps}>
      {children}
      {hasFooter && <Footer fontStyles={footerStyles} />}
      {googleTrackId && <AnalyticScript id={googleTrackId} scriptType="ga" />}
      {facebookPixelTrackId && (
        <AnalyticScript id={facebookPixelTrackId} scriptType="fb" />
      )}
    </Wrapper>
  );
};

export default Layout;
