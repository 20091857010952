import forIn from '@anm/helpers/object/forIn';
import isEqual from 'lodash/fp/isEqual';
import { ReactElement } from 'react';

type InjectGlobalProps = {
  head: ReactElement;
};
type InjectGlobalState = {
  [key in keyof InjectGlobalProps]: InjectGlobalProps[key][];
};
type InjectSectionName = keyof InjectGlobalProps;

const state: InjectGlobalState = { head: [] };

const filterAlreadyAdded = (sections: ReactElement[]) => (newElement: ReactElement) =>
  sections.filter(oldElement => !isEqual(oldElement, newElement));

const addToState = (element: ReactElement, sectionName: InjectSectionName) => {
  const mappedElement = {
    ...element,
    key: element.props?.src
  };

  return (state[sectionName] = [...filterAlreadyAdded(state[sectionName])(mappedElement), mappedElement]);
};

export const injectGlobal = forIn<InjectGlobalProps>(addToState);

export const flushGlobal = (sectionName: InjectSectionName) => {
  const tags = state[sectionName];
  state[sectionName] = [];

  return tags;
};

export const getState = () => state;
