import Container from '@anm/components/Container';
import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import Image from '@anm/components/image/Image';
import getFromCdn from '@anm/helpers/getFromCdn';
import { centeredInFullHeight, device } from '@anm/styles/helpers';
import styled from 'styled-components';

export const FakeHeader = styled.div`
  height: 74px;
  box-shadow: 0 2px 10px rgba(1, 83, 130, 0.1);
  padding: 0 20px;
  display: flex;
  align-items: center;
  @media ${device.laptop} {
    padding: 0 40px;
  }
`;

export const LogoWrapper = styled(Image)`
  width: 140px;
  height: 30px;
  display: inline-block;
`;

export const CTABlock = styled.div`
  padding: 20px 50% 20px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  background-image: url(${getFromCdn('images/video-landings/laptopgirl.png')});
  background-color: #f29397;
  background-position: right 20% bottom;
  background-repeat: no-repeat;
  background-size: auto 95%;

  @media ${device.laptop} {
    padding: 40px 40% 20px 50px;
  }
`;

export const CTATitleBlock = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 70px;
  }
  a {
    color: #292a2b;
    &:hover {
      text-decoration: none;
    }
  }
`;

const ErrorWrapper = styled(Container)`
  ${centeredInFullHeight()};
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Inter', sans-serif;
`;

export const ErrorTitleBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorTitleBlockIcon = styled(Image)`
  width: 30px;
  margin-right: 10px;
`;

export const ErrorTitleIconWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ErrorTitleBlock = styled(TitleBlock)`
  text-align: center;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 18px;
  }
  ${TitleDescription} {
    margin: 0 auto 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
  }
  a {
    color: var(--blue_100);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default ErrorWrapper;
