import React from "react";
var __jsx = React.createElement;
import config from '../../../config';
var isPlayerDevMode = process.env['PLAYER_ENV'] === 'dev';

var PlayerScript = function PlayerScript(_ref) {
  var _ref$isLocal = _ref.isLocal,
      isLocal = _ref$isLocal === void 0 ? false : _ref$isLocal;
  var playerUrl = isPlayerDevMode || isLocal ? "http://localhost:3001/player.js" : "".concat(config.urls.waveUrl, "embed/player.js?").concat(process.env.BUILD_NUMBER);
  return __jsx("script", {
    src: playerUrl,
    async: true
  });
};

export default PlayerScript;