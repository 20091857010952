import React from "react";
var __jsx = React.createElement;
import SafeHead from '@anm/components/SafeHeaad';
import PlayerScript from '../PlayerScript';
import VideoSchema from '../VideoSchema';
import parseMetaHeadProps from './helpers';

var MetaHead = function MetaHead(props) {
  var _parseMetaHeadProps = parseMetaHeadProps(props),
      title = _parseMetaHeadProps.title,
      videoMeta = _parseMetaHeadProps.videoMeta,
      canonicalUrl = _parseMetaHeadProps.canonicalUrl,
      previewUrl = _parseMetaHeadProps.previewUrl,
      description = _parseMetaHeadProps.description,
      oEmbedXMLUrl = _parseMetaHeadProps.oEmbedXMLUrl,
      oEmbedJSONUrl = _parseMetaHeadProps.oEmbedJSONUrl;

  var manifest = props.manifest,
      isLocalPlayer = props.isLocalPlayer,
      error = props.error,
      page = props.page;
  return __jsx(React.Fragment, null, __jsx(SafeHead, null, __jsx("meta", {
    name: "viewport",
    content: "width=device-width, initial-scale=1"
  }), __jsx("meta", {
    httpEquiv: "X-UA-Compatible",
    content: "ie=edge"
  }), __jsx("link", {
    rel: "icon",
    type: "image/x-icon",
    href: "https://assets.animatron.com/landings/static/wv-favicon.ico"
  }), __jsx("title", null, title), canonicalUrl && __jsx(React.Fragment, null, __jsx("link", {
    rel: "canonical",
    href: canonicalUrl
  }), __jsx("meta", {
    property: "og:url",
    content: canonicalUrl
  })), __jsx("meta", {
    name: "robots",
    content: "all"
  }), __jsx("meta", {
    charSet: "utf-8"
  }), previewUrl && __jsx(React.Fragment, null, __jsx("meta", {
    name: "twitter:image",
    content: previewUrl
  }), __jsx("meta", {
    property: "og:image",
    content: previewUrl
  })), __jsx("meta", {
    property: "og:title",
    content: title
  }), __jsx("meta", {
    name: "twitter:title",
    content: title
  }), __jsx("meta", {
    name: "description",
    content: description
  }), __jsx("meta", {
    property: "og:description",
    content: description
  }), __jsx("meta", {
    name: "twitter:description",
    content: description
  }), __jsx("meta", {
    name: "twitter:card",
    content: "summary_large_image"
  }), videoMeta && __jsx(React.Fragment, null, __jsx("meta", {
    property: "og:video:type",
    content: "video/mp4"
  }), __jsx("meta", {
    property: "og:video",
    content: videoMeta.videoUrl
  }), __jsx("meta", {
    property: "og:video:secure_url",
    content: videoMeta.videoUrl
  }), __jsx("meta", {
    property: "og:video:width",
    content: "".concat(videoMeta.videoWidth)
  }), __jsx("meta", {
    property: "og:video:height",
    content: "".concat(videoMeta.videoHeight)
  })), oEmbedJSONUrl && __jsx("link", {
    rel: "alternate",
    type: "application/json+oembed",
    href: oEmbedJSONUrl,
    title: title
  }), oEmbedXMLUrl && __jsx("link", {
    rel: "alternate",
    type: "application/xml+oembed",
    href: oEmbedXMLUrl,
    title: title
  }), manifest && page && __jsx(VideoSchema, {
    page: page,
    manifest: manifest
  }), !(error !== null && error !== void 0 && error.code) && __jsx(PlayerScript, {
    isLocal: isLocalPlayer
  })));
};

export default MetaHead;