type Period = 'H' | 'M' | 'S';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DURATION_PREFIX = 'PT';

const getValueWithSufix = (value: number, period: Period) => {
  if (!value) return '';

  return `${value}${period}`;
};

const durationToISO = (duration: number) => {
  const fullHours = Math.trunc(duration / HOUR);
  const fullMinutes = Math.trunc((duration - fullHours * HOUR) / MINUTE);
  const fullSeconds = Math.trunc(
    duration - fullHours * HOUR - fullMinutes * MINUTE
  );

  const hours = getValueWithSufix(fullHours, 'H');
  const minutes = getValueWithSufix(fullMinutes, 'M');
  const seconds = getValueWithSufix(fullSeconds, 'S');

  return `${DURATION_PREFIX}${hours}${minutes}${seconds}`;
};

export default durationToISO;
