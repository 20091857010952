import _defineProperty from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { DEFAULT_VIDEO_META } from '@anm/data/hostingDefaults';
import getBestMedia from '@anm/helpers/getBestMedia';
import durationToISO from '@anm/helpers/time/durationToISO';
import { getLandingMeta } from '../../components/MetaHead/helpers';
import config from '../../../config';

var injectVideoSchema = function injectVideoSchema(_ref) {
  var page = _ref.page,
      manifest = _ref.manifest;
  var id = manifest.id,
      main = manifest.main;
  var currentDate = new Date().toISOString();

  var _getLandingMeta = getLandingMeta({
    page: page,
    manifest: manifest
  }),
      title = _getLandingMeta.title,
      description = _getLandingMeta.description;

  var duration = manifest.meta.duration ? durationToISO(manifest.meta.duration) : DEFAULT_VIDEO_META.duration;
  return JSON.stringify(_objectSpread(_objectSpread(_objectSpread({
    '@context': 'http://schema.org/',
    '@type': 'VideoObject',
    duration: duration
  }, title && {
    name: title
  }), description && {
    description: description
  }), {}, {
    thumbnailUrl: "".concat(main ? getBestMedia(main, 'image').path : ''),
    embedUrl: "".concat(config.urls.embedUrl).concat(id),
    uploadDate: "".concat(manifest.meta.uploadDate || currentDate)
  }));
};

export default injectVideoSchema;