import { MediaObject, getBestQuality } from '@anm/hosting-player';

type MediaType = 'video' | 'image';

const getBestMedia = (main: MediaObject[], type: MediaType) => {
  const allMedias = main.filter(media => media.type === type);

  const bestMedia = getBestQuality(allMedias);

  return bestMedia || {};
};

export default getBestMedia;
