import Image from '@anm/components/image/Image';
import { device } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

export type TextWrapperProps = {
  fontStyles?: ReturnType<typeof css>;
};

export const Wrapper = styled.div`
  padding: 30px 10px 68px 10px;
  background: rgba(145, 158, 170, 0.1);

  @media ${device.laptop} {
    padding-top: 19px;
    padding-bottom: 39px;
  }
`;

export const LogoWrapper = styled(Image)`
  margin-left: 5px;
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  color: #919eaa;

  p {
    margin: 0;
  }

  ${({ fontStyles }) => fontStyles && `${fontStyles}`}
`;
