import { LandingTheme } from '@anm/api/modules/hosting';
import { DEFAULT_WAVE_COLOR } from '@anm/constants/colors';
import getContrast from '@anm/helpers/color/getContrast';
import getDarkerColor from '@anm/helpers/color/getDarkerColor';
import getHSLColor from '@anm/helpers/color/getHSLColor';
import hex2rgba from '@anm/helpers/color/hex2rgba';
import { css } from 'styled-components';

type LayoutProps = {
  playerColor: string;
};

type CTAButtonProps = {
  bgColor: string;
};

export const layoutVariants: {
  [key in LandingTheme]: ReturnType<typeof css>;
} = {
  pure: css`
    background: #fff;
  `,
  dawn: css`
    background: linear-gradient(
      to bottom,
      ${({ playerColor }: LayoutProps) => `${hex2rgba(playerColor, 0.2)}`},
      rgba(255, 255, 255, 0)
    );
  `,
  pale: css`
    background: ${({ playerColor }: LayoutProps) => `${hex2rgba(playerColor, 0.1)}`};
  `,
  dusk: css`
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      ${({ playerColor }: LayoutProps) => `${hex2rgba(playerColor, 0.2)}`}
    );
  `,
  deep: css`
    background: ${({ playerColor }: LayoutProps) => `${getHSLColor(playerColor)}`};
  `,
  lily: css`
    background: #fffdf8;
  `,
  mist: css`
    background: #dfe0e2;
  `,
  sand: css`
    background: #fcf6ed;
  `,
  dew: css`
    background: #f8f7f5;
  `,
  peony: css`
    background: #fbf1e7;
  `,
  night: css`
    background: #121212;
  `,
  daisy: css`
    background: #fffc71;
  `
};

export const landingCTAVariants: {
  [key in LandingTheme]: ReturnType<typeof css>;
} = {
  pure: css`
    font-weight: bold;
    border-radius: 5px;
    ${({ bgColor }: CTAButtonProps) => css`
      background-color: ${bgColor};

      &:hover {
        background-color: ${getDarkerColor(bgColor)};
      }
    `};
  `,
  dawn: css`
    color: #fff;
    background-color: #292a2b;

    &:hover {
      background-color: #1b1c1d;
    }
  `,
  pale: css`
    border-radius: 5px;
    font-weight: 700;
    color: ${({ bgColor }: CTAButtonProps) => `${getContrast('#fff', bgColor) < 2 ? '#292A2B' : '#fff'}`};

    ${({ bgColor }: CTAButtonProps) => css`
      background-color: ${bgColor};

      &:hover {
        background-color: ${getDarkerColor(bgColor)};
      }
    `};
  `,
  dusk: css`
    border: 2px solid #292a2b;
    color: #292a2b;

    &:hover {
      background-color: ${hex2rgba('#292a2b', 0.05)};
    }
  `,
  deep: css`
    color: ${({ bgColor }: CTAButtonProps) => getHSLColor(bgColor)};
    background-color: #f5f7fa;

    &:hover {
      background-color: #e2e7f0;
    }
  `,
  lily: css`
    color: #292a2b;
    background-color: #5af5b2;

    &:hover {
      background-color: #50e1a8;
    }
  `,
  mist: css`
    background-color: #292a2b;

    &:hover {
      background-color: #1b1c1d;
    }
  `,
  sand: css`
    border: 2px solid #c09850;
    color: #c09850;

    &:hover {
      background-color: #fff;
    }
  `,
  dew: css`
    color: #292a2b;
    border: 2px solid #292a2b;
    border-radius: 25px;

    &:hover {
      background-color: ${hex2rgba('#292a2b', 0.05)};
    }
  `,
  peony: css`
    color: #e39384;
    border: 2px solid #e39384;

    &:hover {
      background-color: #f9e9da;
    }
  `,
  night: css`
    color: #292a2b;
    border-radius: 5px;
    background-color: #f5f7fa;

    &:hover {
      background-color: #e2e7f0;
    }
  `,
  daisy: css`
    border: 2px solid #292a2b;
    color: #292a2b;

    &:hover {
      background-color: ${hex2rgba('292a2b', 0.05)};
    }
  `
};

export const textColorVariants: {
  [key in LandingTheme]: ReturnType<typeof css>;
} = {
  pure: css``,
  dawn: css``,
  pale: css`
    h1,
    p {
      color: ${({ playerColor }: LayoutProps) => getHSLColor(playerColor, 100, 20)} !important;
    }
  `,
  dusk: css``,
  deep: css`
    h1,
    div,
    p {
      color: #fff !important;
    }
  `,
  lily: css``,
  mist: css``,
  sand: css`
    h1,
    p {
      color: #c09850;
    }
  `,
  dew: css``,
  peony: css`
    h1,
    p {
      color: #e39384 !important;
    }
  `,
  night: css`
    h1,
    div,
    p {
      color: #f5f7fa !important;
    }
  `,
  daisy: css``
};

type Pallete = {
  msgText: string;
  msgAuthor: string;
  msgTime: string;
  inputBg: string;
  borderColor: string;
  chatTitle: string;
  sendButtonFilter: string;
  playerColor?: string;
  hideChatBtn?: string;
};

const light: Pallete = {
  msgTime: '#575C5E',
  inputBg: '#333536',
  msgText: '#292A2B;',
  chatTitle: '#292A2B;',
  msgAuthor: '#575C5E',
  borderColor: '1px solid rgba(0, 56, 87, 0.2)',
  hideChatBtn: 'none',
  sendButtonFilter: 'invert(70%) sepia(28%) saturate(7403%) hue-rotate(172deg) brightness(101%) contrast(90%);',
  playerColor: DEFAULT_WAVE_COLOR
};

const dark: Pallete = {
  inputBg: '#E0E7EB',
  msgTime: '#AAB3B9',
  msgText: '#F3F6F7',
  msgAuthor: '#AAB3B9',
  chatTitle: '#AAB3B9',
  hideChatBtn: 'invert(79%) sepia(10%) saturate(225%) hue-rotate(161deg) brightness(91%) contrast(83%)',
  borderColor: 'border: 1px solid rgba(255, 255, 255, 0.2)',
  sendButtonFilter: 'invert(43%) sepia(72%) saturate(2833%) hue-rotate(175deg) brightness(95%) contrast(95%)',
  playerColor: DEFAULT_WAVE_COLOR
};

export const getWebinarColorsByTheme = (theme: LandingTheme): Pallete =>
  ({
    pure: light,
    dawn: light,
    pale: light,
    dusk: light,
    deep: { ...dark, playerColor: '#F46BB3' },
    lily: { ...light, playerColor: '#B47BBA' },
    mist: { ...light, playerColor: '#1877F2' },
    sand: { ...light, playerColor: '#6A635D' },
    dew: { ...light, playerColor: '#3B6B4E' },
    peony: { ...light, playerColor: '#B47468' },
    night: { ...dark, playerColor: '#3F4244' },
    daisy: { ...light, playerColor: '#292A2B' }
  }[theme]);
