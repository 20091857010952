import { FC } from 'react';

import { LogoWrapper, TextWrapper, TextWrapperProps, Wrapper } from './Wrapper';

type FooterProps = {
  footerText?: string;
} & TextWrapperProps;

const defaultFooterText = `This video landing page created in`;

const Footer: FC<FooterProps> = ({ footerText = defaultFooterText, fontStyles }) => (
  <Wrapper>
    <TextWrapper {...{ fontStyles }}>
      <p dangerouslySetInnerHTML={{ __html: footerText }} />
      <LogoWrapper src="images/video-landings/icons/wv-text-logo.svg" alt="Wave video" width="80" height="14" />
    </TextWrapper>
  </Wrapper>
);

export default Footer;
