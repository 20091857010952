import { LandingTheme } from '@anm/api/modules/hosting';
import { ALL_THEMES } from '@anm/constants/videoLandings';
import { injectGlobal } from '@anm/helpers/stateful/injectGlobal';
import { landingFontsMap } from '@anm/styles/fonts';

type InjectFontsProps = {
  currentTheme: LandingTheme;
  isCustomization?: boolean;
};

export const injectFonts = ({ currentTheme, isCustomization }: InjectFontsProps) => {
  const themeList: LandingTheme[] = isCustomization ? ALL_THEMES : [currentTheme];

  themeList.forEach(t => {
    const fontUrls = landingFontsMap[t].url;

    fontUrls.forEach(f =>
      injectGlobal({
        head: <link href={`${f}`} key={f.toString()} rel="preload" as="style" />
      })
    );
  });
};
