import { FC } from 'react';

import ErrorWrapper, {
  CTABlock,
  CTATitleBlock,
  ErrorTitleBlock,
  ErrorTitleBlockIcon,
  ErrorTitleBlockWrapper,
  ErrorTitleIconWrapper,
  FakeHeader,
  LogoWrapper
} from './Wrapper';

export type ErrorProps = {
  title?: string;
  iconSrc?: string;
  description?: string;
};

const defaultErrorIconSrc = 'images/video-landings/icons/shrug.png';

const CTATitle = 'Make, host and share videos.<br/>Anywhere.';
const CTADescription = `Learn more at wave.video/tools/video-hosting`;

const Error: FC<ErrorProps> = ({ iconSrc, description, title }) => {
  return (
    <>
      <FakeHeader>
        <LogoWrapper src="images/logos/wv-logo-140_ua.svg" />
      </FakeHeader>
      <ErrorWrapper size={720}>
        <ErrorTitleBlockWrapper>
          <ErrorTitleIconWrapper>
            <ErrorTitleBlockIcon src={iconSrc || defaultErrorIconSrc} />
            <ErrorTitleBlock size="super-medium" title={title} />
          </ErrorTitleIconWrapper>
          <ErrorTitleBlock size="super-medium" description={description} />
        </ErrorTitleBlockWrapper>
        <CTABlock>
          <CTATitleBlock size="medium" title={CTATitle} description={CTADescription} />
        </CTABlock>
      </ErrorWrapper>
    </>
  );
};

export default Error;
