import isInIframe from '@anm/helpers/is/isInIframe';
import { useCallback, useEffect, useRef } from 'react';

import { FormatNames, Landing, Webinar } from '@anm/api/modules/hosting/types';
import { DEFAULT_VIEW_TYPE } from './../../../../constants/videoLandings';

type UseLayoutHeightProps = {
  page?: Landing | Webinar;
  viewType?: FormatNames;
  isCustomization?: boolean;
};

const useLayoutHeight = ({ page, viewType = DEFAULT_VIEW_TYPE, isCustomization = false }: UseLayoutHeightProps) => {
  const layoutRef = useRef<HTMLDivElement>(null);

  const sendMessage = useCallback((minHeight: number) => window.parent.postMessage({ minHeight }, '*'), [
    layoutRef.current
  ]);

  useEffect(() => {
    if (!layoutRef.current || !isInIframe() || !isCustomization) return;

    const timeId = setTimeout(() => {
      const layoutHeight = layoutRef.current?.scrollHeight;

      layoutHeight && sendMessage(layoutHeight);
    }, 0);

    return () => clearTimeout(timeId);
  }, [page, viewType, layoutRef.current, isCustomization]);

  return layoutRef;
};

export default useLayoutHeight;
