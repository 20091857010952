import injectFBSnippet from '@anm/helpers/trackingSnippets/FBSnippet';
import injectGASnippet from '@anm/helpers/trackingSnippets/GASnippet';
import { FC } from 'react';

type AnalyticScriptProps = {
  id: string;
  scriptType: 'ga' | 'fb';
};

const AnalyticScript: FC<AnalyticScriptProps> = ({ id, scriptType }) => {
  switch (scriptType) {
    case 'ga':
      return (
        <script
          dangerouslySetInnerHTML={{ __html: injectGASnippet(id) }}
        ></script>
      );

    case 'fb':
      return (
        <script
          dangerouslySetInnerHTML={{ __html: injectFBSnippet(id) }}
        ></script>
      );

    default:
      return null;
  }
};
export default AnalyticScript;
