import isNil from 'lodash/isNil';

const getChunksFromString = (st: string, chunkSize: number): string[] => st.match(new RegExp(`.{${chunkSize}}`, 'g'))!;

const convertHexUnitTo256 = (hexStr: string) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

const getAlphafloat = (a: number | undefined, alpha: number) => {
  if (!isNil(a)) {
    return a / 256;
  }
  if (1 < alpha && alpha <= 100) {
    return alpha / 100;
  }
  if (0 <= alpha && alpha <= 1) {
    return alpha;
  }
};

export const getRGBAvalues = (hex: string, alpha = 1) => {
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize) || [];
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256);

  return [r, g, b, getAlphafloat(a, alpha) as number];
};

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b, a] = getRGBAvalues(hex, alpha);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export default hex2rgba;
