import React from "react";
var __jsx = React.createElement;
import injectVideoSchema from '../../helpers/injectVideoSchema';

var VideoSchema = function VideoSchema(props) {
  return __jsx("script", {
    type: "application/ld+json",
    dangerouslySetInnerHTML: {
      __html: injectVideoSchema(props)
    }
  });
};

export default VideoSchema;